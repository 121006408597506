import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import moment from 'moment';

import Coupon from '../coupon/Coupon'

import { PageMainContentState } from '../../../type/couponProps';

import {data_category_items} from '../../../data/data'

import { useSelector } from '../../../store/store';

import { usedOfferGet, basketGet } from '../functions/functions';
import { setErrorRed } from '../../../slice/setError/setErrorSlice';
import { data } from 'autoprefixer';

import { momentIsBetween } from '../functions/functions'

const PointCoupon:React.FC = () => {
    const dispatch = useDispatch();
    const viewState:Number = useSelector((state) => state.viewData.categoryViewState);
    const auth:any = useSelector((state) => state.authentication);
    const retailer:any = useSelector((state) => state.retailerData);

    const [itemState, setItemState] = useState([] as any);
    const [dataGetState, setDataGetState] = useState(false);


    useEffect(() => {
        const setedOffer = basketGet(auth.data.id, auth.data.access_token, retailer.retailerId);
        (async () => {
            if(viewState === 5){
                const startTime = new Date().getTime();
                await setedOffer.then((data) => {
                    const elapsed = new Date().getTime() - startTime;
                    console.log('PointCoupon setedOffer ' + elapsed);
                    if(!('response' in data)){
                        setItemState(data);
                        setDataGetState(true);
                    }else{
                        dispatch(setErrorRed({errorStatus:'error-basket​'}))
                    }
                }).catch(() => dispatch(setErrorRed({errorStatus:'error-basket'})));
            }

            if(viewState === 6){
                const usedOffer = usedOfferGet(auth.data.id, auth.data.access_token);
                let usedDatas:any;
                await usedOffer.then((data) => {
                    if('paid' in data){
                        usedDatas = [...data.paid, ...data.to_be_paid, ...data.validated];
                        setItemState(usedDatas);
                        setDataGetState(true);
                    }else{
                        usedDatas = [];
                        dispatch(setErrorRed({errorStatus:'error-wallet'}));
                    }
                    // let settingData = [...test_data.paid, ...test_data.to_be_paid, ...test_data.validated];
                });
            }
        })();
    },[]);

    if(viewState === 5){
        let pointAdd = 0;
        let couponAdd = 0;
        let couponList;
        if(itemState.length !== 0){
            couponList = itemState.map((CouponItemData:any , index:any) => {
                if(Object.keys(CouponItemData).indexOf('status') !== -1){
                    if(CouponItemData.status  < 6){
                        pointAdd += CouponItemData.offer.discount_value;
                        couponAdd++;
                        let dataObj = {...CouponItemData.offer};
                        dataObj.seted = true;
                        dataObj.used = false;
                        dataObj.ended_at = CouponItemData.offer.ended_at;
                        const betweenFlag = momentIsBetween(new Date(), dataObj.started_at, dataObj.ended_at);
                        dataObj.between = betweenFlag;
                        dataObj.categories.map((cat:any) => {
                            dataObj.webCampaign = false;
                            if(cat === data_category_items[0].param){
                                dataObj.webCampaign = true;
                            }
                        });
                        return <Coupon couponItemDataProp={dataObj} key={index} />
                    }
                }
            });
        }

        return (
            <>
                <div className="basket-information">
                    <div className="basket-information-area">
                        <div className="setted-coupon">
                            <p>セット中のクーポン</p>
                            <p>{couponAdd}<span>枚</span></p>
                        </div>
                        <div><img src="images/icon-caretRight.png" alt=""/></div>
                        <div className="setted-point">
                            <p>クーポン利用で<br/>たまるポイント</p>
                            <p>{pointAdd}<span>pt</span></p>
                        </div>
                    </div>
                </div>
                <div className="coupon-list-area">
                    <div className="coupon-list-items">
                        {dataGetState && itemState.length !== 0 ? couponList : <div className="coupon-list-area">
                            <div className="none-coupon">
                                <p>この条件のクーポンはありません。</p>
                            </div>
                        </div>}
                    </div>
                </div>
            </>
        );
    }else{
        let pointAdd = 0;
        let couponAdd = 0;
        let couponIdAry:any = [];
        let couponList;

        if(itemState.length !== 0){
            couponList = itemState.map((CouponItemData:any , index:any) => {
                if(Object.keys(CouponItemData).indexOf('offer') !== -1){
                    if(couponIdAry.indexOf(CouponItemData.offer.id) === -1){
                        couponIdAry.push(CouponItemData.offer.id);
                        couponAdd+=CouponItemData.points/CouponItemData.offer.discount_value;
                    }
                    // pointAdd += CouponItemData.offer.discount_value;
                    pointAdd += CouponItemData.points;
                    console.log(couponAdd, pointAdd)
                    let dataObj = {...CouponItemData.offer};
                    const betweenFlag = momentIsBetween(new Date(), dataObj.started_at, dataObj.ended_at);
                    dataObj.between = betweenFlag;
                    dataObj.infinite = false;
                    if(dataObj.user_quota >= 2){
                        dataObj.infinite = true;
                    }
                    dataObj.oneTime = false;

                    if(dataObj.user_quota === 1){
                        dataObj.oneTime = true;
                    }
                    dataObj.seted = true;

                    if(betweenFlag === false && dataObj.oneTime){
                        dataObj.used = true;
                        dataObj.seted = false;
                    }else if(betweenFlag && dataObj.oneTime){
                        dataObj.used = true;
                        dataObj.seted = false;
                    }

                    dataObj.started_at = CouponItemData.offer.started_at;
                    dataObj.ended_at = CouponItemData.offer.ended_at;
                    dataObj.categories.map((cat:any) => {
                        dataObj.webCampaign = false;
                        if(cat === data_category_items[0].param){
                            dataObj.webCampaign = true;
                        }
                    });

                    return <Coupon couponItemDataProp={dataObj} key={index} />
                }
            });
        }

        const nowMonth = moment(new Date()).format('YYYY年M月');
        const sixMonthAgo = moment(new Date()).subtract(5, 'M').format('YYYY年M月');
        return (
            <>
                <div className="usage-history-information">
                    <p>利用済みのクーポンとたまるポイント</p>
                    <div className="usage-history-information-area">
                        <div className="setted-coupon">
                            <p>{couponAdd}<span>枚</span></p>
                        </div>
                        <div className="setted-point">
                                <p>{pointAdd}<span>pt</span></p>
                            <p>付与予定のポイント含む</p>
                        </div>
                    </div>
                </div>
                <div className="validity-date-area">
                    <p>期間<span>{sixMonthAgo}～{nowMonth}</span></p>
                </div>
                <div className="coupon-list-area">
                    <div className="coupon-list-items">
                        {dataGetState && itemState.length !== 0 ? couponList : <div className="coupon-list-area"><div className="none-coupon"><p>この条件のクーポンはありません。</p></div></div>}
                    </div>
                </div>
            </>
        );
    }
};

export default PointCoupon;