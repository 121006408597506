import { PayloadAction } from '@reduxjs/toolkit';

import { PageMainContentState } from '../../type/couponProps'

export const categoryViewFunc = (state:PageMainContentState, action:PayloadAction<PageMainContentState>) => {
    if(state.categoryViewState === 2){
        state.categoryViewState = 3;
    }else{
        state.preViewState = state.categoryViewState.toString();
    }
    state.preViewState = state.categoryViewState.toString();
    state.categoryViewState = Number(action.payload.categoryViewState);
    state.category = action.payload.category;
};

export const detailViewFunc = (state:PageMainContentState, action:PayloadAction<PageMainContentState>) => {
    if(state.categoryViewState === 6){
        state.preViewState = state.categoryViewState.toString();
    }else{
        state.preViewState = state.categoryViewState.toString();
    }

    state.categoryViewState = Number(action.payload.categoryViewState);
    state.category = action.payload.category;
    state.detail = action.payload.detail;
    state.detailStatus = action.payload.detailStatus;
};