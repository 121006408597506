import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PageMainContentState } from '../../type/couponProps'

import { categoryViewFunc, detailViewFunc } from './viewFunc'


const viewSlice = createSlice({
    name: 'viewData',
    initialState: {
        categoryViewState: 0,
        category:'0',
        detail:'',
        detailStatus:'',
        preViewState:'0'
    },
    reducers: {
        categoryView: {
            reducer: categoryViewFunc,
            prepare(payload: PageMainContentState){
                return {payload}
            }
        },
        detailPageView: {
            reducer: detailViewFunc,
            prepare(payload: PageMainContentState){
                return {payload}
            }
        }
    },
});

export const { categoryView, detailPageView } = viewSlice.actions;
export default viewSlice.reducer;