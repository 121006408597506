import React, { useEffect } from 'react';
import './App.css';
import './css/reset.css'
import './css/styles.css'

import Page_wrap from './component/Page_wrap';

// import { reactGaInit, reactGaPageview } from './component/main/functions/functions';
import { reactTagManagerInit } from './component/main/functions/functions';



const App:React.FC = () => {

  useEffect(() => {
    reactTagManagerInit();
  },[]);

  return (
    <Page_wrap />
  );
}

export default App;
