import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from "axios";

import { reactTagManagerEvClickWebCampaignDetail, reactTagManagerEvClickSetBtn } from '../functions/functions';

import { store, useSelector } from '../../../store/store';

import { setOfferRed } from '../../../slice/setOffer/setOfferSlice';
import { setErrorRed } from '../../../slice/setError/setErrorSlice';

const CouponBtn:React.FC<{couponBtnDataProp:any}> = ({couponBtnDataProp}) => {
    const dispatch = useDispatch();
    const [btnState, setBtnState] = useState('onset');
    const [loading, setLoading] = useState(false);

    const auth:any = useSelector((state) => state.authentication);
    const retailer:any = useSelector((state) => state.retailerData);

    const offerSet = async (id:string, accessToken:string, retailerId:string, offerId:string) => {
        const bearer = `Bearer ${accessToken}`;
        const headers:any = {
            'Content-Type':'application/json',
            'Cache-Control': 'no-cache',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY,
            'Authorization': bearer
        };
        const sendData = [{
            "retailer_id": retailerId,
            "offer_id": offerId
        }];

        try {
            const response = await axios({
                url: `${process.env.REACT_APP_MEMBERS_URL}/${id}/basket`,
                method:'POST',
                headers:headers,
                data:sendData
            });

            if(response.status === 200){
                dispatch(setOfferRed({offerId: couponBtnDataProp.id ,offerStatus:'set'}));
                setBtnState('seted');
            }else{
                dispatch(setErrorRed({errorStatus:'errorSet-basket'}));
            }
            return response.data;
        }
        catch (e:any) {
            dispatch(setErrorRed({errorStatus:'errorSet-basket'}));
            console.log(e);
        }
    };

    const setOffer = () => {
        offerSet(auth.data.id, auth.data.access_token, retailer.retailerId, couponBtnDataProp.id);
    };

    if(couponBtnDataProp.webCampaign){
        return (
            <div className="campaign-button">
                <span onClick={() => {
                    window.location.href = couponBtnDataProp.detail_url;
                    reactTagManagerEvClickWebCampaignDetail(couponBtnDataProp.id, auth.data.id);
                }}>詳しくはこちら</span>
            </div>
        );
    }

    if(couponBtnDataProp.seted){
        return(
            <div className="coupon-button setted-button">
                <span>
                    <img src="images/icon-setted.svg" alt=""/> セット済
                </span>
            </div>
        );
    }

    if(btnState === 'seted'){
        return(
            <div className="coupon-button setted-button">
                <span>
                    <img src="images/icon-setted.svg" alt=""/> セット済
                </span>
            </div>
        );
    }

    if(couponBtnDataProp.used){
        return(
            <div className="coupon-button used-button">
                <span>利用済</span>
            </div>
        );
    }

    return (
        <div className="coupon-button">
            <button disabled={loading} onClick={() => {
                setLoading(true);
                setOffer();
                reactTagManagerEvClickSetBtn(couponBtnDataProp.id, auth.data.id);
            }}>
                <img src="images/icon-set.svg" alt="" /> セット
            </button>
        </div>
    );
}

export default CouponBtn;