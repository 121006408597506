import React, { useEffect } from 'react';

import { useSelector } from '../../../store/store';

import {
    reactTagManagerEvViewOfferItem,
    reactTagManagerEvViewBasketItem,
    reactTagManagerEvViewUsageHistoryItem,
} from '../functions/functions';

export function useOnScreen(targetRef: React.RefObject<HTMLElement>) {
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.categoryViewState);

    // const [targetViewPosition, setTargetViewPosition] = useState<
    //     TargetViewPosition
    // >(undefined);

    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                if(viewState === 0 || viewState === 1){
                    reactTagManagerEvViewOfferItem(targetRef.current?.dataset.id, auth.data.id);
                }

                if(viewState === 5){
                    reactTagManagerEvViewBasketItem(targetRef.current?.dataset.id, auth.data.id);
                }

                if(viewState === 6){
                    reactTagManagerEvViewUsageHistoryItem(targetRef.current?.dataset.id, auth.data.id);
                }

                return;
            }
        },
        {
            root: null,
            threshold: 0,
        }
    );

    useEffect(() => {
        if (targetRef.current) observer.observe(targetRef.current);

        return () => {
            observer.disconnect();
        };
    }, []);
}