import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { CategoryTypeProp } from '../../type/couponProps';

import { category_info } from '../../data/data'

import { useSelector } from '../../store/store';
import { categoryView } from '../../slice/views/viewSlice';

import { setOfferRed } from '../../slice/setOffer/setOfferSlice';

// import { reactGaInit, reactGaClick } from '../main/functions/functions';
import {
    reactTagManagerEvClickBackBtn,
    reactTagManagerEvClickCategory,
    reactTagManagerEvClickBackBtnDetail,
    reactTagManagerEvClickMenuBackBtn,
    reactTagManagerEvClickBasketItemBackBtn,
    reactTagManagerEvClickUsageHistoryBackBtn
} from '../main/functions/functions';

const Category:React.FC<CategoryTypeProp> = ({ categories }) => {
    const dispatch = useDispatch();
    const viewObj:any = useSelector((state) => state.viewData);
    const viewState:Number = useSelector((state) => state.viewData.categoryViewState);
    const selectedCat:string = useSelector((state) => state.viewData.category);
    const category_info_json:any = JSON.parse(category_info);
    const categoryInfo:string = category_info_json[selectedCat];
    const auth:any = useSelector((state) => state.authentication);

    let actionPayload = viewObj;

    const categoryItems = categories.map( (category, index) => {
        let actionPayload = {
            categoryViewState: 1,
            category:category.param,
            detail:'',
            detailStatus:'',
            preViewState:''
        };

        if(category.param === '0'){
            actionPayload.categoryViewState = 0;
            return (
                <li className="category-item" key={index}>
                    <div onClick={() => {
                        dispatch(categoryView(actionPayload));
                        dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));

                        reactTagManagerEvClickCategory('all', auth.data.id);
                    }}>
                        <div className="category-image">
                            <img src={`${process.env.PUBLIC_URL}/images/${category.imgPath}`} alt=""/>
                        </div>
                        <p className="category-name">{category.categoryName}</p>
                    </div>
                </li>
            );
        }

        if(category.param === '1'){
            return (
                <li className="category-item" key={index}>
                    <div onClick={() => {
                        dispatch(categoryView(actionPayload));
                        dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                        reactTagManagerEvClickCategory('new', auth.data.id);
                    }}>
                        <div className="category-image">
                            <img src={`${process.env.PUBLIC_URL}/images/${category.imgPath}`} alt=""/>
                        </div>
                        <p className="category-name">{category.categoryName}</p>
                    </div>
                </li>
            );
        }

        return (
            <li className="category-item" key={index}>
                <div onClick={() => {
                    dispatch(categoryView(actionPayload));
                    dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                    reactTagManagerEvClickCategory(category.param, auth.data.id);
                }}>
                    <div className="category-image">
                        <img src={`${process.env.PUBLIC_URL}/images/${category.imgPath}`} alt=""/>
                    </div>
                    <p className="category-name">{category.categoryName}</p>
                </div>
            </li>
        );
    });

    actionPayload = {
        categoryViewState: 0,
        category:'0',
        detail:'',
        detailStatus:'',
        preViewState:viewObj.categoryViewState.toString()
    };

    switch (viewState) {
        case 0:
            return (
                <div id="category-list" className="category-list">
                    <div className="slide-area">
                        <ul className="category-list-items">
                            {categoryItems}
                        </ul>
                    </div>
                </div>
            );
            break;
        case 1:
            return (
                <div id="page-title" className="page-title">
                    <span className='all_item_link' onClick={() => {
                        dispatch(categoryView(actionPayload));
                        dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                        reactTagManagerEvClickBackBtn(auth.data.id);
                    }}><img src="images/icon-angleLeft.png" alt="" />すべて</span>
                    <p><span id="coupon-type">{categoryInfo}</span>クーポン</p>
                </div>
            );
            break;
        case 3:
            return (
                <div id="page-title" className="page-title">
                    <span className='all_item_link' onClick={() => {
                        dispatch(categoryView(actionPayload));
                        dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                        reactTagManagerEvClickMenuBackBtn(auth.data.id);
                    }}><img src="images/icon-angleLeft.png" alt="" />すべて</span>
                    <p><span id="coupon-type">すべての</span>メニュー</p>
                </div>
            );
            break;
        case 4:
            return (
                <></>
            );
            break;
        case 5:
            return (
                <div id="page-title" className="page-title">
                    <span className='all_item_link' onClick={() =>{
                        dispatch(categoryView(actionPayload));
                        dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                        reactTagManagerEvClickBasketItemBackBtn(auth.data.id);
                    }} data-gtm-id="coupon-click-event" data-gtm-key="menu_back_button"><img src="images/icon-angleLeft.png" alt="" />すべて</span>
                    <p><span id="coupon-type">セット中</span>のクーポン</p>
                </div>
            );
            break;
        case 6:
            return (
                <div id="page-title" className="page-title">
                    <span className='all_item_link' onClick={() => {
                        dispatch(categoryView(actionPayload));
                        dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                        reactTagManagerEvClickUsageHistoryBackBtn(auth.data.id);
                    }} data-gtm-id="coupon-click-event" data-gtm-key="menu_back_button"><img src="images/icon-angleLeft.png" alt="" />すべて</span>
                    <p><span id="coupon-type">利用済みの</span>クーポン</p>
                </div>
            );
            break;
        default:
            actionPayload.categoryViewState = viewObj.preViewState;
            actionPayload.category = viewObj.category;
            return (
                <div id="page-title" className="page-title">
                    <span className='all_item_link' onClick={() => {
                        dispatch(categoryView(actionPayload));
                        dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                        reactTagManagerEvClickBackBtnDetail(auth.data.id);
                    }} data-gtm-id="coupon-click-event" data-gtm-key="offer_detail_back_button"><img src="images/icon-angleLeft.png" alt="" />戻る</span>
                </div>
            );
    }

}

export default Category;