import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { member } from './member'

export const memberSlice = createSlice({
    name: 'member',
    initialState: {
        status: 'idle',
        data:{}
    },
    reducers: {
        postMember: (state) => {
            state.data = { status: 'idle', data: [] };
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(member.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(member.fulfilled, (state, action) => {
            state.status = 'idle';
            state.data = action.payload;
        });
    },
});

export const { postMember } = memberSlice.actions;
export default memberSlice.reducer;