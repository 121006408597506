import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";

import { ApiResponse } from '../../type/couponProps'

import {hashCode, reactTagManagerEvFirstView} from '../../component/main/functions/functions'
import {log} from "util";
const headers:any = {
    'Content-Type':'application/json',
    'Cache-Control': 'no-cache',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY
};

const login = async (orgId:any, memberId:any, password?:any) => {
    const email = orgId.toString() + memberId.toString() + '@catalina.com';
    console.log(orgId,password,password?hashCode(password):password)
    const startTime = new Date().getTime();
    const response = await axios.request({
        url: process.env.REACT_APP_LOGIN_URL,
        method:'POST',
        headers:headers,
        data:{
            "email": email,
            "password": password? password: hashCode(email),
        }
    });
    const elapsed = new Date().getTime() - startTime;
    console.log('authentication ' + elapsed);

    reactTagManagerEvFirstView(response.data.id, response.data.id);
    return  response.data;
}
export const authentication = createAsyncThunk<ApiResponse, {status:boolean, orgId:string, memberId:string}>(
    'authentication',
    async ({orgId, memberId}) => {
        try {
            let response = await login(orgId, memberId)
            return response
        }
        catch (e:any) {
            let password;
            try {
                let response = await login(orgId, memberId, password = 'pass1234!')
                return response
            } catch (e: any) {
                let password;
            try {
                let response = await login(orgId, memberId, password = 'Password.1')
                return response
            } catch (e: any) {
                if(e.response.statusText){
                    return {
                        status: e.response.statusText,
                        data: e,
                    };
                }else{
                    return {
                        status: 'failed',
                        data: e,
                    };
                }
            }
            }

        }
    }
);