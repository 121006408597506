import React, {useEffect, useState} from 'react';

import Category from './Category';

import { useSelector } from '../../store/store';
import { useDispatch } from 'react-redux';
import { category_items } from '../../data/data'
import Iframe from 'react-iframe'
import ReactModal from 'react-modal';
import spinner from './spinner.gif'
import './loader.css'
import {XMarkIcon} from "@heroicons/react/20/solid";
import axios from "axios";
import {
    getRetailerFromEnv
} from "../main/functions/functions";
import {setErrorRed} from "../../slice/setError/setErrorSlice";

ReactModal.setAppElement('#root');

const Header: React.FC = () => {
    const auth: any = useSelector((state) => state.authentication);
    const selectedCat:string = useSelector((state) => state.viewData.category);
    const viewObj:any = useSelector((state) => state.viewData);

    const [data, setData] = useState<any>({});
    const [url, setUrl] = useState<any>();

    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const couponList: any = useSelector((state) => state.coupon.data);
    let [hideBanner,setHideBanner] = useState(true);
    let [banner, setBanner] = useState<any>(null)
    const retailerFromEnv = getRetailerFromEnv()
    const getData = async () => {
        const bearer = `Bearer ${auth.data.access_token}`;
        const headers:any = {
            'Content-Type':'application/json',
            'Cache-Control': 'no-cache',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY,
            'Authorization': bearer
        };
        const paramUrl = `${process.env.REACT_APP_BASE_URL}/spotlights/mobile?retailer_id=${retailerFromEnv.retailer_id}&member_id=${auth.data.id}`;
        const { data } = await axios.request({
            url: paramUrl,
            method:'GET',
            headers:headers
        });
        console.log(data)
        let banner = data[0];
        if(banner){
            setBanner(banner);
            setHideBanner(false)
            if(banner!.destination.location_type === 'offer'){
                detailGet(banner!.destination.offer_location.offer_id)
            }
        }

    };

    const detailGet = async (id:string) => {
        const headers:any = {
            'Content-Type':'application/json',
            'Cache-Control': 'no-cache',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY
        };


        try {
            const startTime = new Date().getTime();
            const response = await axios({
                url: `${process.env.REACT_APP_BASE_URL}/offers/${id}`,
                method:'GET',
                headers:headers
            });
            const elapsed = new Date().getTime() - startTime;
            console.log('Detail ' + elapsed);
            setData(response.data);
        }
        catch (e:any) {
            dispatch(setErrorRed({errorStatus:'error-offer'}));
            console.log(e);
        }
    };
    let actionPayload = {
        categoryViewState: 2,
        category:viewObj.category,
        detail: '',
        detailStatus:'',
        preViewState:''
    };

    const setOffer = (id:any) => {
        setUrl(data.detail_url)
        setIsOpen(true);
    };


    useEffect(()=>{
        if (auth.status === 'idle' && auth.data.id){
            getData();
        }
    },[auth]);
    function openModal() {
        setUrl("https://faq.waonpoint.jp/category/show/55?site_domain=default")
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    if (auth.status === 'idle') {

        return (
            <>

                <ReactModal
                    isOpen={modalIsOpen}
                    className="Modal"
                    overlayClassName="Overlay"
                >

                    <div className="top">
                        <div className="inner_container">
                            <div className="top-logo">
                                <img src="images/waonA.png" alt="" />
                                <p>WAON POINT クーポン</p>
                                <div><img src={`${process.env.PUBLIC_URL}/images/question.png`} alt="" onClick={
                                    () => {
                                    }
                                } /></div>
                            </div>
                            <div id="page-title" className="page-title">
                                <span className='all_item_link' onClick={() => {
                                    closeModal()
                                }}><img src="images/icon-angleLeft.png" alt="" />すべて</span>
                            </div>
                        </div>
                    </div>
                    <Iframe url={url}
                        sandbox="allow-same-origin"
                        width="100%"
                        height="100%"
                        id="FAQ"
                        className=""
                        display="block"
                        position="relative" />
                </ReactModal>
                {couponList.status != 'idle' && auth.status === 'idle' &&
                    <div className="center">
                        <div className="loader">
                            <img src={spinner} alt="Loading" title='読み込み中' />
                        </div>
                        <div  className="loader" style={{
                                color: '#af1d7b',
                                fontWeight:'bold',
                                fontSize:'large'
                            }}>読み込み中
                        </div>
                    </div>
                }
                {'status' in couponList && (couponList.status === 'idle') &&
                    <div className="top">
                        <div className="inner_container">
                            <div className="top-logo">
                                <img src="images/waonA.png" alt="" />
                                <p>WAON POINT クーポン</p>
                                <div><img src={`${process.env.PUBLIC_URL}/images/question.png`} alt="" onClick={
                                    () => {
                                        openModal()
                                    }
                                } /></div>
                        {/*        <div><img src={`${process.env.PUBLIC_URL}/images/question.png`} alt=""  onClick={*/}
                        {/*    () => {*/}
                        {/*        let url = 'https://faq.waonpoint.jp/category/show/55?site_domain=default'*/}
                        {/*        // window.location.href = 'https://faq.waonpoint.jp/category/show/55?site_domain=default'*/}
                        {/*        window.open(url, '_system');*/}
                        {/*    }*/}
                        {/*}/></div>*/}
                            </div>
                            <Category categories={category_items} />

                            {banner && !hideBanner && selectedCat === '0' &&
                            <div className="flex items-center align-middle gap-x-6 bg-white px-6 py-2.5 sm:px-3.5 sm:before:flex-1" style={{
                                border: '1px solid #af1d7b',
                                zIndex: 1,
                                opacity: hideBanner ? "0" : "1",
                                transition: "all 5s",
                                visibility: hideBanner ? "hidden" : "visible",
                            }}>
                                <div className="text-sm leading-6 flex" style={{
                                    color: '#af1d7b',
                                    fontWeight:'bold',
                                    fontSize:'14px'
                                }}>
                                    <div className="flex justify-center align-middle text-center">
                                        {/*{banner!.picture_url &&*/}
                                        {/*    <img src={banner!.picture_url} className="flex h-6 align-middle"/>*/}
                                        {/*}*/}
                                        {
                                            banner!.destination.location_type === 'url' ?
                                                <a href={banner!.destination.url_location.url} target="_self" className="flex flex-1 text-xs py-1 ml-2 break-all" > {banner!.name} </a> : banner!.destination.location_type === 'offer' ? <div className="text-red-500 break-all" onClick={()=>{setOffer(banner.destination.offer_location.offer_id)}}>{banner!.name}</div> : <div>{banner!.name}</div>
                                        }

                                    </div>
                                </div>
                                <div className="flex flex-1 justify-end">
                                    <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                                        onClick={()=>{
                                            setHideBanner(true)
                                        }
                                    }
                                    >
                                        <span className="sr-only">Dismiss</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" style={{
                                            color: '#af1d7b',
                                            fontWeight:'bold',
                                            fontSize:'14px'
                                        }} />
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>

                }
            </>
        );
    } else {
        return (
            <></>
        );
    }
}

export default Header;