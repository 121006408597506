import React, { useEffect, useState } from 'react';

import SelectedCoupon from './SelectedCoupon';
import MenuCategory from './MenuCategory';

import { menu_data } from '../../../data/data'

const Menu:React.FC = () => {
    return (
        <div className="category-list">
            <MenuCategory menuData={menu_data.all} />
            <MenuCategory menuData={menu_data.uses} />
            <MenuCategory menuData={menu_data.categories} />
            <SelectedCoupon />
        </div>
    );
};

export default Menu;