import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { offerState } from '../../type/couponProps'

import { setOfferFunc} from './setOfferFunc'


const offerSlice = createSlice({
    name: 'offerData',
    initialState: {
        offerId:'',
        offerStatus:'none'
    },
    reducers: {
        setOfferRed: {
            reducer: setOfferFunc,
            prepare(payload: offerState){
                return {payload}
            }
        }
    },
});

export const { setOfferRed } = offerSlice.actions;
export default offerSlice.reducer;