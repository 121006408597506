import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { errorState } from '../../type/couponProps'

import { setErrorFunc} from './setErrorFunc'


const errorSlice = createSlice({
    name: 'errorData',
    initialState: {
        errorStatus:''
    },
    reducers: {
        setErrorRed: {
            reducer: setErrorFunc,
            prepare(payload: errorState){
                return {payload}
            }
        }
    },
});

export const { setErrorRed } = errorSlice.actions;
export default errorSlice.reducer;