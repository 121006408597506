import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { retailerState } from '../../type/couponProps'

import { retailerFunc} from './retailerFunc'


const retailerSlice = createSlice({
    name: 'retailerData',
    initialState: {
        logoUrl:'',
        retailerId:''
    },
    reducers: {
        retailerRed: {
            reducer: retailerFunc,
            prepare(payload: retailerState){
                return {payload}
            }
        }
    },
});

export const { retailerRed } = retailerSlice.actions;
export default retailerSlice.reducer;