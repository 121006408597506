
import { Category, Category_info, MenuCategory } from '../type/couponProps';

export const category_items:Category[] = [
    {
        param:'0',
        imgPath:'icon-all.svg',
        categoryName:'すべて'
    },
    {
        param:'1',
        imgPath:'icon-new.svg',
        categoryName:'NEW'
    },
    {
        param:process.env.REACT_APP_CAT_MEDICINE,
        imgPath:'icon-medicine.svg',
        categoryName:'医薬品'
    },
    {
        param:process.env.REACT_APP_CAT_COSMETIC,
        imgPath:'icon-cosmetic.svg',
        categoryName:'化粧品'
    },
    {
        param:process.env.REACT_APP_CAT_FOOD,
        imgPath:'icon-food.svg',
        categoryName:'食品'
    },
    {
        param:process.env.REACT_APP_CAT_SUPPLEMENT,
        imgPath:'icon-supplement.svg',
        categoryName:'サプリ'
    },
    {
        param:process.env.REACT_APP_CAT_DRINK,
        imgPath:'icon-drink.svg',
        categoryName:'飲料'
    },
    {
        param:process.env.REACT_APP_CAT_GOODS,
        imgPath:'icon-goods.svg',
        categoryName:'日用品'
    },
    {
        param:process.env.REACT_APP_CAT_PET,
        imgPath:'icon-pet.svg',
        categoryName:'ペット'
    },
    {
        param:process.env.REACT_APP_CAT_ALCOHOL,
        imgPath:'icon-alcohol.svg',
        categoryName:'酒類'
    },
    {
        param:process.env.REACT_APP_CAT_BABY,
        imgPath:'icon-baby.svg',
        categoryName:'ベビー'
    },
    {
        param:process.env.REACT_APP_CAT_OTHERS,
        imgPath:'icon-others.svg',
        categoryName:'その他'
    }
];

// export const category_items:Category[] = [
//   {
//       param:'0',
//       imgPath:'icon-all.svg',
//       categoryName:'すべて'
//   },
//   {
//       param:'1',
//       imgPath:'icon-new.svg',
//       categoryName:'NEW'
//   },
//   {
//       param:'126',
//       imgPath:'icon-medicine.svg',
//       categoryName:'医薬品'
//   },
//   {
//       param:'127',
//       imgPath:'icon-cosmetic.svg',
//       categoryName:'化粧品'
//   },
//   {
//       param:'128',
//       imgPath:'icon-food.svg',
//       categoryName:'食品'
//   },
//   {
//       param:'129',
//       imgPath:'icon-supplement.svg',
//       categoryName:'サプリ'
//   },
//   {
//       param:'130',
//       imgPath:'icon-drink.svg',
//       categoryName:'飲料'
//   },
//   {
//       param:'131',
//       imgPath:'icon-goods.svg',
//       categoryName:'日用品'
//   },
//   {
//       param:'135',
//       imgPath:'icon-pet.svg',
//       categoryName:'ペット'
//   },
//   {
//       param:'136',
//       imgPath:'icon-alcohol.svg',
//       categoryName:'酒類'
//   },
//   {
//       param:'137',
//       imgPath:'icon-baby.svg',
//       categoryName:'ベビー'
//   },
//   {
//       param:'138',
//       imgPath:'icon-others.svg',
//       categoryName:'その他'
//   }
// ];

export const data_category_items:any[] = [
    {
        param:process.env.REACT_APP_CAT_WEB,
        categoryName:'ウェブキャンペーン'
    },
    {
        param:process.env.REACT_APP_CAT_MEDICINE,
        categoryName:'医薬品'
    },
    {
        param:process.env.REACT_APP_CAT_COSMETIC,
        categoryName:'化粧品'
    },
    {
        param:process.env.REACT_APP_CAT_FOOD,
        categoryName:'食品'
    },
    {
        param:process.env.REACT_APP_CAT_SUPPLEMENT,
        categoryName:'サプリ'
    },
    {
        param:process.env.REACT_APP_CAT_DRINK,
        categoryName:'飲料'
    },
    {
        param:process.env.REACT_APP_CAT_GOODS,
        categoryName:'日用品'
    },
    {
        param:process.env.REACT_APP_CAT_PET,
        categoryName:'ペット'
    },
    {
        param:process.env.REACT_APP_CAT_ALCOHOL,
        categoryName:'酒類'
    },
    {
        param:process.env.REACT_APP_CAT_BABY,
        categoryName:'ベビー'
    },
    {
        param:process.env.REACT_APP_CAT_OTHERS,
        categoryName:'その他'
    }
];

// export const data_category_items:any[] = [
//   {
//     param:'139',
//     categoryName:'ウェブキャンペーン'
//   },
//   {
//       param:'126',
//       categoryName:'医薬品'
//   },
//   {
//       param:'127',
//       categoryName:'化粧品'
//   },
//   {
//       param:'128',
//       categoryName:'食品'
//   },
//   {
//       param:'129',
//       categoryName:'サプリ'
//   },
//   {
//       param:'130',
//       categoryName:'飲料'
//   },
//   {
//       param:'131',
//       categoryName:'日用品'
//   },
//   {
//       param:'135',
//       categoryName:'ペット'
//   },
//   {
//       param:'136',
//       categoryName:'酒類'
//   },
//   {
//       param:'137',
//       categoryName:'ベビー'
//   },
//   {
//       param:'138',
//       categoryName:'その他'
//   }
// ];
export const category_info:any = process.env.REACT_APP_CATEGORY_INFO?.replace(/\|/g,'"');

// export const category_info:Category_info = {
//   '126': '医薬品の',
//   '127': '化粧品の',
//   '128': '食品の',
//   '129': 'サプリの',
//   '130': '飲料の',
//   '131': '日用品の',
//   '135': 'ペットの',
//   '136': '酒類の',
//   '137': 'ベビーの',
//   '138': 'その他の',
//   'web': 'WEB',
//   '1': '新着',
//   'set': 'セットする'
// };

export const menu_data:MenuCategory = {
    'all':{
        title:'all',
        class:'all',
        items:[
            category_items[0],
            category_items[1]
        ]
    },
    'uses':{
        title:'使い方で選ぶ',
        class:'uses',
        items:[
            {
                param:'set',
                imgPath:'icon-set.svg',
                categoryName:'セットする',
            },
            {
                param:'web',
                imgPath:'icon-web.svg',
                categoryName:'WEB',
            }
        ]
    },
    'categories':{
        title:'カテゴリで選ぶ',
        class:'categories',
        items:[
            category_items[2],
            category_items[3],
            category_items[4],
            category_items[5],
            category_items[6],
            category_items[7],
            category_items[8],
            category_items[9],
            category_items[10],
            category_items[11]
        ]
    }
}

// export const menu_data:MenuCategory = {
//     'all':{
//         title:'all',
//         class:'all',
//         items:[
//             category_items[0],
//             category_items[1]
//         ]
//     },
//     'uses':{
//         title:'使い方で選ぶ',
//         class:'uses',
//         items:[
//             {
//                 param:'set',
//                 imgPath:'icon-set.svg',
//                 categoryName:'セットする',
//             },
//             {
//                 param:'web',
//                 imgPath:'icon-web.svg',
//                 categoryName:'WEB',
//             }
//         ]
//     },
//     'categories':{
//         title:'カテゴリで選ぶ',
//         class:'categories',
//         items:[
//             category_items[2],
//             category_items[3],
//             category_items[4],
//             category_items[5],
//             category_items[6],
//             category_items[7],
//             category_items[8],
//             category_items[9],
//             category_items[10],
//             category_items[11]
//         ]
//     }
// }