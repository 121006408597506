import {store} from "../../../store/store";
import {categoryView} from "../../../slice/views/viewSlice";
import {setErrorRed} from "../../../slice/setError/setErrorSlice";

export const requestErrorFn = function (error:any) {
    console.log('Interceptor request Error', error)
    // Do something with request error
    return Promise.reject(error);
}

export const preRequestFn = function (config:any) {
    // Do something before request is sent
    return config;
}

export const errorFn = function (error:any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    let actionPayload = {
        categoryViewState: 10,
        category: '0',
        detail: '',
        detailStatus: '',
        preViewState: ''
    };
    console.log('Interceptor response Error', error)
    if (error.code === 'ECONNABORTED' || error.code === 'ERR_NETWORK'){
        const state = store.getState();
        store.dispatch(categoryView(actionPayload));
        store.dispatch(setErrorRed({ errorStatus: 'error-network' }));
    }
    else
    {
        return Promise.reject(error);
    }
    // return Promise.reject(error);
}

export const Fn200 = function (response:any) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log('Interceptor response ')
    return response;
}

