import React from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '../../store/store';
import { categoryView } from '../../slice/views/viewSlice';

import { reactTagManagerEvClickAllMenuOfferList } from '../main/functions/functions'
import Iframe from "react-iframe";
import ReactModal from "react-modal";

const AllLink:React.FC = () => {
    const dispatch = useDispatch();
    const viewState:Number = useSelector((state) => state.viewData.categoryViewState);
    const auth:any = useSelector((state) => state.authentication);
    const couponList:any = useSelector((state) => state.coupon.data);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
    function closeModal() {
        setIsOpen(false);
    }
    const actionPayload = {
        categoryViewState: 3,
        category:'0',
        detail:'',
        detailStatus:'',
        preViewState:''
    };

    return (
        <>
        <div id="to-all" className="to-all">
            {'status' in couponList && (couponList.status === 'idle') &&
                <div className="link-parent">
                    {viewState === 0 &&<span id="link" onClick={() => {
                    openModal();
                    // window.location.href = "https://faq.waonpoint.jp/?site_domain=default";
                    // dispatch(categoryView(actionPayload));
                    // if(viewState === 0){
                    //     reactTagManagerEvClickAllMenuOfferList(auth.data.id);
                    // }
                }}>
                    <p>お知らせ</p>
                    <img src={`${process.env.PUBLIC_URL}/images/icon-angleRight.png`} alt="" />
                </span>
                    }
                    <span id="link-2" onClick={() => {
                        dispatch(categoryView(actionPayload));
                        if(viewState === 0){
                            reactTagManagerEvClickAllMenuOfferList(auth.data.id);
                        }
                    }}>
                <p>すべてのメニュー</p>
                <img src={`${process.env.PUBLIC_URL}/images/icon-angleRight.png`} alt="" />
            </span>
                </div>


            }
        </div>
            <ReactModal
                isOpen={modalIsOpen}
                className="Modal"
                overlayClassName="Overlay"
            >

                <div className="top">
                    <div className="inner_container">
                        <div className="top-logo">
                            <img src="images/waonA.png" alt="" />
                            <p>WAON POINT クーポン</p>
                            <div><img src={`${process.env.PUBLIC_URL}/images/question.png`} alt="" onClick={
                                () => {
                                }
                            } /></div>
                        </div>
                        <div id="page-title" className="page-title">
                                <span className='all_item_link' onClick={() => {
                                    closeModal()
                                }}><img src="images/icon-angleLeft.png" alt="" />すべて</span>
                        </div>
                    </div>
                </div>
                <Iframe url="https://faq.waonpoint.jp/info_and_news/show/4?site_domain=default"
                        sandbox="allow-same-origin"
                        width="100%"
                        height="100%"
                        id="FAQ"
                        className=""
                        display="block"
                        position="relative" />
            </ReactModal>
            </>
    );
}

export default AllLink;