import React from 'react';
import { Provider } from "react-redux";

import Header from './header/Header';
import Main from './main/Main';

import { store } from '../store/store';


const Page_wrap:React.FC = () => {
    return (
        <Provider store={store}>
            <div className="wrapper index-wrapper" id="main">
                <Header />
                <Main />
            </div>
        </Provider>
    );
}

export default Page_wrap;