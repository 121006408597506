import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";

import { ApiResponse } from '../../type/couponProps'

import { data_category_items } from '../../data/data';

import {basketGet, momentDateDiffcheck} from '../../component/main/functions/functions';
import {setErrorRed} from "../setError/setErrorSlice";

export const coupon = createAsyncThunk<ApiResponse, {memberId:string, accessToken:string, retailerId:string}>(
    'coupon',
    async ({memberId, retailerId, accessToken}) => {
        let result:ApiResponse[] = [];
        let status = '';
        const startTime = new Date().getTime();
        // await Promise.all([1].map(async (val, index) =>{
            let basket = await basketGet(memberId, accessToken, retailerId);
            console.log(basket)
            // basket.then((data:any) => {
            // if(!('response' in data)){
            //     console.log(data)
            // }else{
            //
            // }
            // }).catch(() => {
            //
            // });
            const paramUrl = `${process.env.REACT_APP_BASE_URL}/offers?member_id=${memberId}&retailer_id=${retailerId}&per_page=1000`;
            const bearer = `Bearer ${accessToken}`;
            const headers:any = {
                'Content-Type':'application/json',
                'Cache-Control': 'no-cache',
                'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY,
                'Authorization': bearer
            };

            try {
                const response = await axios.request({
                    url: paramUrl,
                    method:'GET',
                    headers:headers
                });

                result.push(
                    {
                        status: 'idle',
                        data: response.data,
                    }
                );
            }
            catch (e:any) {
                status = 'failed';
                return {
                    status: 'failed',
                    data: e,
                };
            }
        // }));
        const elapsed = new Date().getTime() - startTime;
        console.log('coupon ' + elapsed);

        let couponListData:any = [];
        const objSrt = (arr:any) => {
            // arr.sort((a:any, b:any) => {
            //     return (a.todayDist < b.todayDist) ? -1 : 1;
            // });
            arr.sort((a:any, b:any) => a.precedence - b.precedence);
            return arr;
        };

        if(Array.isArray(result)){
            result.map((val:any, index:any) =>{
                couponListData = [...couponListData, ...val.data.items];
                status = val.status;
            });
            let basketSet = new Set();
            let offerInBasket:any = {}
            basket.map((basketItem:any, index:any) => {
                basketSet.add(basketItem.offer.id)
                offerInBasket[basketItem.offer.id] = basketItem.offer
            });
            let webCampaign:any = [];
            let special:any = [];
            let usuallyBest:any = [];
            let usually:any = [];
            let pushedIDAry:any = [];
            couponListData.map( (couponData:any) => {
                if(basketSet.has(couponData.id)){
                    basketSet.delete(couponData.id)
                }
            })
            console.log('Left with ',basketSet)
            for (const item of Array.from(basketSet)) {
                let offerExpiredDate = momentDateDiffcheck(offerInBasket[Number(item)].ended_at);
                if(offerExpiredDate>=0)
                couponListData.push(offerInBasket[Number(item)]);
            }
            couponListData.map( (couponData:any) => {
                let couponDataPush = {...couponData};

                couponData.webCampaign = false;
                couponData.todayEndDist = momentDateDiffcheck(couponDataPush.ended_at);
                couponData.todayStartDist = momentDateDiffcheck(couponDataPush.started_at);

                if(pushedIDAry.indexOf(couponDataPush.id) !== -1){
                    pushedIDAry.push(couponDataPush.id);
                }

                couponData.categories.map((cat:any) => {
                    if(cat.id.toString() === data_category_items[0].param){
                        couponData.webCampaign = true;
                        // webCampaign.push(couponDataPush);
                        return;
                    }

                    if(couponDataPush.title.indexOf('スペシャルクーポン') > -1){
                        if(special.indexOf(couponDataPush)==-1){
                            special.push(couponDataPush);
                            return;
                        }
                    } else if(couponDataPush.best){
                        if(usuallyBest.indexOf(couponDataPush)==-1) {
                            usuallyBest.push(couponDataPush);
                        }
                        return;
                    } else {
                        usually.push(couponDataPush);
                        return;
                    }


                    // if(cat.id.toString() === data_category_items[1].param){
                    //     special.push(couponDataPush);
                    //     return;
                    // }


                });
            });
            let findDuplicates = (arr:any) => {
                return arr.filter((currentValue:any, currentIndex:any) =>
                    arr.indexOf(currentValue) !== currentIndex);
            }



            // webCampaign = objSrt(webCampaign);
            // console.log('webCampaign',findDuplicates(webCampaign))
            // special = objSrt(special);
            // console.log('special',findDuplicates(special))
            // usuallyBest = objSrt(usuallyBest);
            // console.log('usuallyBest',findDuplicates(usuallyBest))
            // usually = objSrt(usually);
            // console.log('usually',findDuplicates(usually))

            // couponListData = [...webCampaign, ...special, ...usuallyBest, ...usually];
            // console.log('complete data', findDuplicates(couponListData));
            // console.log(couponListData.length);
            couponListData = objSrt(couponListData)

        }

        let retData = {
            status:status,
            data:couponListData
        }

        return retData;
    }
);