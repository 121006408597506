import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useSelector as rawUseSelector, TypedUseSelectorHook } from 'react-redux';

import viewSlice from '../slice/views/viewSlice';
import authenticationSlice from '../slice/authentication/authenticationSlice';
import memberSlice from '../slice/member/memberSlice';
import couponSlice from '../slice/coupon/couponSlice';
import retailerSlice from '../slice/retailer/retailerSlice';
import setOfferSlice from '../slice/setOffer/setOfferSlice';
import setErrorSlice from '../slice/setError/setErrorSlice';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

const reducer = combineReducers({
    viewData: viewSlice,
    retailerData: retailerSlice,
    authentication: authenticationSlice,
    member: memberSlice,
    coupon: couponSlice,
    setOffer: setOfferSlice,
    setError: setErrorSlice
});

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})

export const store = configureStore({
    reducer,
    middleware: customizedMiddleware
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector;