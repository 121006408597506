import React from 'react';
import { useDispatch } from 'react-redux';

import { MenuCategoryData } from '../../../type/couponProps'

import { categoryView } from '../../../slice/views/viewSlice';
import { setOfferRed } from '../../../slice/setOffer/setOfferSlice';

import { store, useSelector } from '../../../store/store';

import { reactTagManagerEvVClickCategory, reactTagManagerEvClickAllMenuOfferList } from '../functions/functions'

const MenuCategory: React.FC<{menuData:MenuCategoryData}> = ({menuData}) => {
    const dispatch = useDispatch();
    const auth:any = useSelector((state) => state.authentication);

    const menuItems = menuData.items.map((data, index) => {
        let actionPayload = {
            categoryViewState: 1,
            category:data.param,
            detail:'',
            detailStatus:'',
            preViewState:''
        };

        if(data.param === '0'){
            actionPayload.categoryViewState = 0;
            return(
                <li className="category-item" key={index} onClick={() => {
                    dispatch(categoryView(actionPayload));
                    dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                    reactTagManagerEvVClickCategory('all', auth.data.id);
                }}>
                    <div className="category-image">
                        <img src={`${process.env.PUBLIC_URL}/images/${data.imgPath}`} alt="" />
                    </div>
                    <p className="category-name">{data.categoryName}</p>
                </li>
            );
        }

        if(data.param === 'web'){
            let paramVal = '';
            if(process.env.REACT_APP_CAT_WEB){
                paramVal = process.env.REACT_APP_CAT_WEB;
            }

            return(
                <li className="category-item" key={index} onClick={() => {
                    dispatch(categoryView(actionPayload));
                    dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                    reactTagManagerEvVClickCategory(paramVal, auth.data.id);
                }}>
                    <div className="category-image">
                        <img src={`${process.env.PUBLIC_URL}/images/${data.imgPath}`} alt="" />
                    </div>
                    <p className="category-name">{data.categoryName}</p>
                </li>
            );
        }

        if(data.param === '1'){
            return(
                <li className="category-item" key={index} onClick={() => {
                    dispatch(categoryView(actionPayload));
                    dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                    reactTagManagerEvVClickCategory('new', auth.data.id);
                }}>
                    <div className="category-image">
                        <img src={`${process.env.PUBLIC_URL}/images/${data.imgPath}`} alt="" />
                    </div>
                    <p className="category-name">{data.categoryName}</p>
                </li>
            );
        }

        return(
            <li className="category-item" key={index} onClick={() => {
                dispatch(categoryView(actionPayload));
                dispatch(setOfferRed({offerId: '' ,offerStatus:'none'}));
                reactTagManagerEvVClickCategory(data.param, auth.data.id);
            }}>
                <div className="category-image">
                    <img src={`${process.env.PUBLIC_URL}/images/${data.imgPath}`} alt="" />
                </div>
                <p className="category-name">{data.categoryName}</p>
            </li>
        );
    });


    if(menuData.title === 'all'){
        return (
            <div className="alls">
                <ul className="category-list-items">
                    {menuItems}
                </ul>
            </div>
        );
    }else{
        return (
            <>
                <div className="menu-title">
                    <p>{menuData.title}</p>
                </div>
                <div className={menuData.class}>
                    <ul className="category-list-items">
                        {menuItems}
                    </ul>
                </div>
            </>
        );
    }
};

export default MenuCategory;