import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { coupon } from './coupon'

export const couponSlice = createSlice({
    name: 'authentication',
    initialState: {
        status: 'idle',
        data:{}
    },
    reducers: {
        getCoupon: (state) => {
            state.data = { status: 'idle', data: [] };
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(coupon.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(coupon.fulfilled, (state, action) => {
            state.status = 'idle';
            state.data = action.payload;
        });
    },
});

export const { getCoupon } = couponSlice.actions;
export default couponSlice.reducer;