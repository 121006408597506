import React from 'react';
import { useDispatch } from 'react-redux';

import { PageMainContentState } from '../../../type/couponProps'

import { detailPageView } from '../../../slice/views/viewSlice';

import { useSelector } from '../../../store/store';

import { reactTagManagerEvClickOnset, reactTagManagerEvClickUsed } from '../functions/functions'

const SelectedCoupon:React.FC = () => {
    const dispatch = useDispatch();

    const auth:any = useSelector((state) => state.authentication);

    return (
        <div className="bottom-buttons">
            <div className="menu-title">
                <p>選んだクーポン</p>
            </div>
            <div className="category-buttons">
                <span  onClick={ () => {
                    let actionPayload = {
                        categoryViewState: 2,
                        category:'0',
                        detail:'',
                        detailStatus:'',
                        preViewState:''
                    };

                    actionPayload.categoryViewState = 5;
                    dispatch(detailPageView(actionPayload));
                    reactTagManagerEvClickOnset(auth.data.id);
                }} data-gtm-id="coupon-click-event" data-gtm-key="menu_onset_button">セット中</span>
                <span  onClick={ () => {
                    let actionPayload = {
                        categoryViewState: 2,
                        category:'0',
                        detail:'',
                        detailStatus:'',
                        preViewState:''
                    };

                    actionPayload.categoryViewState = 6;
                    dispatch(detailPageView(actionPayload));
                    reactTagManagerEvClickUsed(auth.data.id);
                }} data-gtm-id="coupon-click-event" data-gtm-key="menu_used_button">利用済</span>
            </div>
        </div>
    );
};

export default SelectedCoupon;