import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { animateScroll as scroll } from 'react-scroll';

import AllLink from './AllLink';
import Coupon_area from './coupon/Coupon_area';
import Detail from './Detail';
import Menu from './menu/Menu';
import PointCoupon from './point_area/PointCoupon';
import Error from '../error/Error';
import Error_dialog from '../error/Error_dialog';

import { getRetailerFromEnv, urlParamCheck } from './functions/functions';

import { useSelector } from '../../store/store';
import { categoryView } from '../../slice/views/viewSlice';
import { retailerRed } from '../../slice/retailer/retailerSlice';
import { authentication } from '../../slice/authentication/authentication';
import { member } from '../../slice/member/member';
import { setOfferRed } from '../../slice/setOffer/setOfferSlice';
import { setErrorRed } from '../../slice/setError/setErrorSlice';

import {
    reactTagManagerEvClickFloatingBtn,
    reactTagManagerEvClickFloatingBtnBskt,
    reactTagManagerEvClickFloatingBtnUsage,
    reactTagManagerEvViewAllMenu,
    reactTagManagerEvViewBasketList,
    reactTagManagerEvViewUsageHistoryList
} from './functions/functions';
import ErrorNetwork from "../error/Error_Network";

const Main: React.FC = () => {
    const dispatch = useDispatch();
    const viewState: Number = useSelector((state) => state.viewData.categoryViewState);
    const auth: any = useSelector((state) => state.authentication);
    const offerSetStatus: any = useSelector((state) => state.setOffer.offerStatus);
    const errorStatus: any = useSelector((state) => state.setError.errorStatus);
    const couponList: any = useSelector((state) => state.coupon.data);
    const memberStatus: any = useSelector((state) => state.member);

    const pramcheck = urlParamCheck();
    const retailerFromEnv = getRetailerFromEnv()
    const scrollToTop = () => {
        scroll.scrollToTop();
        if (viewState === 0) {
            reactTagManagerEvClickFloatingBtn(auth.data.id);
        }

        if (viewState === 5) {
            reactTagManagerEvClickFloatingBtnBskt(auth.data.id);
        }

        if (viewState === 6) {
            reactTagManagerEvClickFloatingBtnUsage(auth.data.id);
        }
    };

    useEffect(() => {
        let actionPayload = {
            categoryViewState: 0,
            category: '0',
            detail: '',
            detailStatus: '',
            preViewState: ''
        };

        let logo_pic_url: any = process.env.REACT_APP_RETAILER_PIC;
        let org_id: any = process.env.REACT_APP_RETAILER_ORGID;

        if (!(pramcheck.status)) {
            actionPayload.categoryViewState = 4;
            dispatch(categoryView(actionPayload));
            dispatch(setErrorRed({ errorStatus: 'error-param' }));
        } else {
            dispatch(retailerRed({ logoUrl: retailerFromEnv.retailer_pic, retailerId: retailerFromEnv.retailer_id }));
        }

        if (auth.status === 'loading' && (memberStatus.status === 'idle' && !Object.keys(memberStatus.data).length)) {
            dispatch(authentication(pramcheck));
        }

        if (!window.navigator.onLine) {
            dispatch(setErrorRed({ errorStatus: 'error-network' }));
        }
    }, []);

    useEffect(() => {
        if (pramcheck.status && auth.status !== 'loading' && ('status' in auth.data && auth.data.status === 'Not Found')) {
            if (memberStatus.status !== 'loading' && !Object.keys(memberStatus.data).length) {
                dispatch(member(pramcheck));
            } else {
                if (memberStatus.status !== 'loading' && memberStatus.data.status != 'failed')
                    setTimeout(()=>{
                        dispatch(authentication(pramcheck))
                    }, 1000)

            }
        }

        if (offerSetStatus.indexOf('set') === -1) {
            window.scrollTo(0, 0);
        }

        if (offerSetStatus === 'set') {
            setTimeout(() => {
                dispatch(setOfferRed({ offerId: '', offerStatus: 'setTimeOut' }));
            }, 6000);
        }

        if (viewState === 3) {
            if (!errorStatus) {
                reactTagManagerEvViewAllMenu(auth.data.id);
            }
        }

        if (viewState === 5) {
            if (!errorStatus) {
                reactTagManagerEvViewBasketList(auth.data.id);
            }
        }

        if (viewState === 6) {
            if (!errorStatus) {
                reactTagManagerEvViewUsageHistoryList(auth.data.id);
            }
        }
    });

    if (auth.status === 'idle' && 'id' in auth.data) {
        switch (viewState) {
            case 0:
                return (
                    <>
                        <div className="main">
                            <div className="inner_container">
                                <AllLink />
                                {errorStatus.indexOf('error') === -1 ? <Coupon_area /> : <></>}
                            </div>
                        </div>
                        {'status' in couponList && (couponList.status === 'idle') &&
                            <div className="floating-button border border-solid text-center font-bold fixed" onClick={scrollToTop}>
                                <span><img className="inline-block align-baseline" src="images/icon-angleUp.png" alt="" /></span>
                            </div>
                        }
                        {offerSetStatus === 'set' && errorStatus.indexOf('error') === -1 ? <div className="set-information">
                            <p className="information-text">
                                対象商品をご購入の際、クーポンをセットしたウエルシアグループのアプリで表示するWAON POINTのバーコード、もしくはウエルシアグループのアプリに登録済のWAON POINTが貯まるカードをご提示ください
                            </p>
                        </div> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
                break;
            case 2:
                return (
                    <>
                        <div className='wrapper detail-wrapper'>
                            <div className="main">
                                <div className="inner_container">
                                    {errorStatus.indexOf('error') === -1 ? <Detail /> : <></>}
                                </div>
                            </div>
                        </div>
                        {offerSetStatus === 'set' ? <div className="set-information">
                            <p className="information-text">
                                対象商品をご購入の際、クーポンをセットしたウエルシアグループのアプリで表示するWAON POINTのバーコード、もしくはウエルシアグループのアプリに登録済のWAON POINTが貯まるカードをご提示ください
                            </p>
                        </div> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
                break;
            case 3:
                return (
                    <>
                        <div className='wrapper menu-wrapper'>
                            <div className="main">
                                <div className="inner_container">
                                    {errorStatus.indexOf('error') === -1 ? <Menu /> : <></>}
                                </div>
                            </div>
                        </div>
                        {offerSetStatus === 'set' ? <div className="set-information">
                            <p className="information-text">
                                対象商品をご購入の際、クーポンをセットしたウエルシアグループのアプリで表示するWAON POINTのバーコード、もしくはウエルシアグループのアプリに登録済のWAON POINTが貯まるカードをご提示ください
                            </p>
                        </div> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
                break;
            case 4:
                return (
                    <div className="wrapper error-wrapper">
                        <Error />
                    </div>
                );
                break;
            case 5:
            case 6:
                return (
                    <>
                        <div className='wrapper basket-wrapper'>
                            <div className="main">
                                <div className="inner_container">
                                    {errorStatus.indexOf('error') === -1 ? <PointCoupon /> : <></>}
                                </div>
                            </div>
                        </div>
                        {'status' in couponList && (couponList.status === 'idle') &&
                            <div className="floating-button border border-solid text-center font-bold fixed" onClick={scrollToTop}>
                                <span><img className="inline-block align-baseline" src="images/icon-angleUp.png" alt="" /></span>
                            </div>
                        }
                        {offerSetStatus === 'set' ? <div className="set-information">
                            <p className="information-text">
                                対象商品をご購入の際、クーポンをセットしたウエルシアグループのアプリで表示するWAON POINTのバーコード、もしくはウエルシアグループのアプリに登録済のWAON POINTが貯まるカードをご提示ください
                            </p>
                        </div> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
                break;
            default:
                return (
                    <>
                        <div className="main usage-history-wrapper">
                            <div className="inner_container">
                                <AllLink />
                                {errorStatus.indexOf('error') === -1 ? <Coupon_area /> : <></>}
                            </div>
                        </div>
                        {'status' in couponList && (couponList.status === 'idle') &&
                            <div className="floating-button border border-solid text-center font-bold fixed" onClick={scrollToTop}>
                                <span><img className="inline-block align-baseline" src="images/icon-angleUp.png" alt="" /></span>
                            </div>
                        }
                        {offerSetStatus === 'set' ? <div className="set-information">
                            <p className="information-text">
                                対象商品をご購入の際、クーポンをセットしたウエルシアグループのアプリで表示するWAON POINTのバーコード、もしくはウエルシアグループのアプリに登録済のWAON POINTが貯まるカードをご提示ください
                            </p>
                        </div> : <></>}
                        <Error_dialog errorStringProp={errorStatus} />
                    </>
                );
                break;
        }
    } else {
        if (errorStatus.indexOf('error') === -1) {
            return (
                <></>
            );
        } else if (errorStatus.indexOf('error-network') > -1) {
            return (
                <div className="wrapper error-wrapper">
                    <ErrorNetwork />
                </div>
            );
        } else {
            return (
                <div className="wrapper error-wrapper">
                    <Error />
                </div>
            );
        }
    }
};

export default Main;