import axios from "axios";
import moment from 'moment';

import { FirstParam } from '../../../type/couponProps'

// import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';
import CryptoJS from "crypto-js";
import {errorFn, Fn200, preRequestFn, requestErrorFn} from "./errorfunctions";
// Add a request interceptor
axios.interceptors.request.use(preRequestFn, requestErrorFn);

// Add a response interceptor
axios.interceptors.response.use(Fn200, errorFn);
export const digit = (numberStr: string) => {
    const ary: Array<string> = Array.from(numberStr);
    const lastNumber: any = ary.pop();
    let digitNumber: number;
    let result: boolean;
    let odd: number = 0;
    let even: number = 0;

    ary.map((value, index) => {
        index = index + 1;
        if (index % 2 === 0) {
            even += Number(value);
        } else {
            odd += Number(value);
        }
    });

    digitNumber = 10 - (odd + even * 3) % 10;
    if (digitNumber === 10) {
        digitNumber = 0;
    }

    result = digitNumber === Number(lastNumber);

    return result;
};

const CURRENT_ENV = process.env.REACT_APP_ENV as string;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY as string;
const IV = process.env.REACT_APP_IV as string;

let code = (function () {
    const iv = CryptoJS.enc.Utf8.parse(IV);
    return {
        encryptMessage: function (messageToencrypt = "") {
            var encryptedMessage = CryptoJS.AES.encrypt(
                messageToencrypt,
                CryptoJS.enc.Utf8.parse(SECRET_KEY),
                { iv }
            );
            console.log(encryptedMessage.toString());
            return encryptedMessage.toString();
        },
        decryptMessage: function (encryptedMessage = "") {
            return CryptoJS.AES.decrypt(
                encryptedMessage,
                CryptoJS.enc.Utf8.parse(SECRET_KEY),
                { iv }
            ).toString(CryptoJS.enc.Utf8);
        },
    };
})();

export const getRetailerFromEnv = () => {
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    let retailer_array = JSON.parse(process.env.REACT_APP_ORG_IDS?.replace(/\|/g, '"') as string)
    const alignment: any = params.get('alignment');

    let retailer_id = retailer_array[alignment]
    let retailer_pic = retailer_array[alignment + "_pic"]
    return {
        retailer_id, retailer_pic
    }
}
//Hashing of the password
export const hashCode = function (str: string) {
    var hash = 0,
        i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
export const urlParamCheck = () => {
    const url: URL = new URL(window.location.href);
    const params: URLSearchParams = url.searchParams;
    const idParams: any = params.get('id');
    const alignment: any = params.get('alignment');
    let cardNo = "";

    let result: FirstParam = {
        status: true,
        orgId: "",
        memberId: "",
        retailerId: ""
    };

    if (idParams === null || alignment === null) {
        result.status = false;
        return result;
    }

    // if(idParams.length !== 17){
    //     result.status = false;
    //     return result;
    // }

    if (CURRENT_ENV === "qa") {
        cardNo = code.decryptMessage(
            code.encryptMessage(idParams!.replace(/ /g, "+"))
        );
        if (
            !cardNo ||
            // cardNo.length !== 16 ||
            !/^\d+$/.test(cardNo)
        ) {
            result.status = false;
            return result;
        }
    } else {
        if (idParams) {
            try {
                cardNo = code.decryptMessage(
                    idParams!.replace(/ /g, "+")
                );
                if (
                    !cardNo ||
                    // cardNo.length !== 16 ||
                    !/^\d+$/.test(cardNo)
                ) {
                    result.status = false;
                    return result;
                }
            } catch (e) {
                result.status = false;
                return result;
            }
        }
    }

    // const checkDigit = digit(idParams);

    // if (!checkDigit) {
    //     result.status = false;
    //     return result;
    // }

    result.orgId = cardNo!.slice(0, 4);
    result.memberId = cardNo!.slice(4, cardNo!.length);
    console.log("Decrypted Card Number length:" + cardNo.length);
    console.log("Decrypted Card Number :" + cardNo);
    const orgIdNum: number = Number(result.orgId);
    const envOrgIdNum: number = Number(process.env.REACT_APP_RETAILER_ID);

    // if (orgIdNum !== envOrgIdNum) {
    //     result.status = false;
    //     return result;
    // } else {
        const retailerId: any = process.env.REACT_APP_RETAILER_ORGID;
        result.retailerId = retailerId.toString();
    // }

    return result;
};

export const basketGet = async (id: string, accessToken: string, retailerId: string) => {
    const bearer = `Bearer ${accessToken}`;
    const headers: any = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY,
        'Authorization': bearer
    };
    try {
        const response = await axios({
            url: `${process.env.REACT_APP_MEMBERS_URL}/${id}/basket?retailer_id=${retailerId}`,
            method: 'GET',
            headers: headers,
            timeout: 15000
        });
        return response.data;
    }
    catch (e: any) {
        console.log(e);
        return e;
    }
};

export const usedOfferGet = async (id: string, accessToken: string) => {
    const RFC_3339 = 'YYYY-MM-DDTHH:mm:ss';
    const now = moment(new Date()).format(RFC_3339) + 'TDZ';
    const sixMonthAgo = moment(new Date()).subtract(5, 'M').format(RFC_3339) + 'TDZ';

    const bearer = `Bearer ${accessToken}`;
    const headers: any = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY,
        'Authorization': bearer
    };
    try {
        const response = await axios({
            url: `${process.env.REACT_APP_MEMBERS_URL}/${id}/wallet?from=${sixMonthAgo}&to=${now}&retailer_id=${process.env.REACT_APP_RETAILER_ORGID}`,
            method: 'GET',
            headers: headers,
            timeout: 15000
        });

        return response.data;
    }
    catch (e: any) {
        console.log(e);
        return e;
    }
};

export const offerSet = async (id: string, accessToken: string, retailerId: string, offerId: string) => {
    const bearer = `Bearer ${accessToken}`;
    const headers: any = {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCP_API_SUBSCRIPTION_KEY,
        'Authorization': bearer
    };
    const sendData = [{
        "retailer_id": retailerId,
        "offer_id": offerId
    }];

    try {
        const response = await axios({
            url: `${process.env.REACT_APP_MEMBERS_URL}/${id}/basket`,
            method: 'POST',
            headers: headers,
            data: sendData,
            timeout: 15000
        });

        return response.data;
    }
    catch (e: any) {
        console.log(e);
    }
};

export const replaceTxt = (str: string) => {
    let result;

    result = str.replace(/利用条件/g, '<span class="spcialString">利用条件</span>');
    result = result.replace(/対象店舗/g, '<span class="spcialString">対象店舗</span>');
    result = result.replace(/ご利用上の注意/g, '<span class="spcialString">ご利用上の注意</span>');
    result = result.replace(/クーポンお問い合わせ番号/g, '<span class="spcialString">クーポンお問い合わせ番号</span>');

    return result;
}


export const momentDateDiffcheck = (day: any) => {
    const result = moment(moment(day).format('Y/M/D')).diff(moment(moment().format('Y/M/D')), 'days');

    return result;
}

export const momentMDFmt = (day: any) => {
    const result = moment(day).format('M/D');

    return result;
}

export const momentIsBetween = (today: any, start: any, end: any) => {
    const todayMoment = moment(moment(today).format('Y/M/D'));
    const startDayMoment = moment(moment(start).format('Y/M/D')).subtract(1, 'd');
    const endDayMoment = moment(moment(end).format('Y/M/D')).add(1, 'd');

    const result = todayMoment.isBetween(startDayMoment, endDayMoment);

    return result;
}

declare const window: WindowWithDataLayer;
type WindowWithDataLayer = Window & {
    dataLayer: Record<string, any>[];
};


export const reactTagManagerInit = () => {
    TagManager.initialize({
        gtmId: `${process.env.REACT_APP_GTM_ID}`
    });
};

export const reactTagManagerEvFirstView = (str: string, idStr: string) => {
    const data = {
        event: 'firstView',
        get_key2: `${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickCategory = (str: string, idStr: string) => {
    const data = {
        event: 'pageTopCatClickEv',
        get_key2: `offer_list_category_button_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewOfferList = (idStr: string) => {
    const data = {
        event: 'offerListViewEv',
        get_key2: `offer_list`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewOfferItem = (str: any, idStr: string) => {
    const data = {
        event: 'offerItemViewEv',
        get_key2: `offer_list_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickOfferFrame = (str: string, idStr: string) => {
    const data = {
        event: 'offerItemClickEv',
        get_key2: `offer_list_todetail_button_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickAllMenuOfferList = (idStr: string) => {
    const data = {
        event: 'allMenuClickEv',
        get_key2: `offer_list_allmenu_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickWebCampaignDetail = (str: string, idStr: string) => {
    const data = {
        event: 'webCampaignDetailClickEv',
        get_key2: `offer_list_campaign_button_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickSetBtn = (str: string, idStr: string) => {
    const data = {
        event: 'setBtnClickEv',
        get_key2: `offer_list_setoffer_button_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickFloatingBtn = (idStr: string) => {
    const data = {
        event: 'floatingBtnClickEv',
        get_key2: `offer_list_floating_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickFloatingBtnBskt = (idStr: string) => {
    const data = {
        event: 'basketFloatingBtnClickEv',
        get_key2: `basket_list_floating_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickFloatingBtnUsage = (idStr: string) => {
    const data = {
        event: 'usageFloatingBtnClickEv',
        get_key2: `usage_history_floating_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickBackBtn = (idStr: string) => {
    const data = {
        event: 'backBtnClickEv',
        get_key2: `offer_list_back_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewDetail = (str: string, idStr: string) => {
    const data = {
        event: 'detailViewEv',
        get_key2: `offer_detail_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickSetBtnDetail = (str: string, idStr: string) => {
    const data = {
        event: 'setBtnDetailClickEv',
        get_key2: `offer_detail_set_button_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickBackBtnDetail = (idStr: string) => {
    const data = {
        event: 'backBtnDetailClickEv',
        get_key2: `offer_detail_back_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewAllMenu = (idStr: string) => {
    const data = {
        event: 'allMenuViewEv',
        get_key2: `menu`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvVClickCategory = (str: string, idStr: string) => {
    const data = {
        event: 'categoryClickEv',
        get_key2: `menu_category_button_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickOnset = (idStr: string) => {
    const data = {
        event: 'onsetClickEv',
        get_key2: `menu_onset_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickUsed = (idStr: string) => {
    const data = {
        event: 'usedClickEv',
        get_key2: `menu_used_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickMenuBackBtn = (idStr: string) => {
    const data = {
        event: 'menuBackBtnClickEv',
        get_key2: `menu_back_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewBasketList = (idStr: string) => {
    const data = {
        event: 'basketListViewEv',
        get_key2: `basket_list`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewBasketItem = (str: any, idStr: string) => {
    const data = {
        event: 'basketItemViewEv',
        get_key2: `basket_list_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickBasketItemFrame = (str: string, idStr: string) => {
    const data = {
        event: 'basketItemFrameClickEv',
        get_key2: `basket_list_todetail_button_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickBasketItemBackBtn = (idStr: string) => {
    const data = {
        event: 'basketItemBackBtnClickEv',
        get_key2: `basket_list_back_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewUsageHistoryList = (idStr: string) => {
    const data = {
        event: 'usageHistoryListViewEv',
        get_key2: `usage_history`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewUsageHistoryItem = (str: any, idStr: string) => {
    const data = {
        event: 'usageHistoryItemViewEv',
        get_key2: `usage_history_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickUsageHistoryBackBtn = (idStr: string) => {
    const data = {
        event: 'usageHistoryBackBtnClickEv',
        get_key2: `usage_history_back_button`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvClickUsageHistoryItem = (str: string, idStr: string) => {
    const data = {
        event: 'usageHistoryItemClickEv',
        get_key2: `usage_history_todetail_button_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewError = (str: string, idStr: string) => {
    const data = {
        event: 'errorViewEv',
        get_key2: `error_${str}`,
        user_id: `${idStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewErrorDialog = (idStr: string, apiStr: string, userIdStr: string) => {
    const data = {
        event: 'errorViewEvDialog',
        get_key2: `error_dialog_${idStr}_${apiStr}`,
        user_id: `${userIdStr}`
    };
    window.dataLayer.push(data);
};

export const reactTagManagerEvViewErrorDialogBtn = (idStr: string, userIdStr: string) => {
    const data = {
        event: 'errorViewEvDialogBtn',
        get_key2: `error_dialog_button_${idStr}`,
        user_id: `${userIdStr}`
    };
    window.dataLayer.push(data);
};