import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { basketGet } from '../functions/functions';

import { useSelector } from '../../../store/store';

import Coupon from './Coupon';
import { coupon } from '../../../slice/coupon/coupon';
import { setErrorRed } from '../../../slice/setError/setErrorSlice';

import { momentDateDiffcheck, reactTagManagerEvViewOfferList } from '../functions/functions'

import { category_info } from '../../../data/data'

const Coupon_area:React.FC = () => {
    const dispatch = useDispatch();
    const selectedCat:string = useSelector((state) => state.viewData.category);
    const couponList:any = useSelector((state) => state.coupon.data);
    const auth:any = useSelector((state) => state.authentication);
    const retailer:any = useSelector((state) => state.retailerData);
    const viewState:Number = useSelector((state) => state.viewData.categoryViewState);

    const [itemState, setItemState] = useState([] as any);
    const [loadFlag, setLoadFlag] = useState(false);
    let basket;
    useEffect(() => {
        let couponParam = {
            memberId:auth.data.id,
            accessToken:auth.data.access_token,
            retailerId:retailer.retailerId
        };
        console.log(couponParam);

        if(selectedCat !== 'web'){
            (async () => {
                await dispatch(coupon(couponParam));
                basket = basketGet(auth.data.id, auth.data.access_token, retailer.retailerId);
                basket.then((data) => {
                    if(!('response' in data)){
                        setItemState(data);
                        setLoadFlag(true);
                    }else{
                        setItemState([]);
                        dispatch(setErrorRed({errorStatus:'errorGet-basket'}));
                    }
                }).catch(() => {dispatch(setErrorRed({errorStatus:'errorGet-basket'}));});
            })();
        }else{
            setLoadFlag(true);
        }

        if(viewState === 0){
            reactTagManagerEvViewOfferList(auth.data.id);
        }
    } ,[]);

    if(couponList.status === 'failed'){
        dispatch(setErrorRed({errorStatus:'errorGet-offer'}));
    }

    let couponItemList;
    let viewFlag = true;
    const catInfoJson = JSON.parse(category_info);
    if(couponList.status && loadFlag){
        couponItemList = couponList.data.map( (CouponItemData:any, index:any)  => {
            let dataObj = {...CouponItemData};
            dataObj.seted = false;
            itemState.map((basketItem:any, index:any) => {
                if(basketItem.offer.id === CouponItemData.id){
                    dataObj.seted = true;
                }
            });

            if(momentDateDiffcheck(dataObj.started_at) <= 0){
                if(selectedCat !== '0'){
                    if(selectedCat === '1'){
                        if(momentDateDiffcheck(dataObj.started_at) >= -7){
                            viewFlag = false;
                            return (
                                <Coupon couponItemDataProp={dataObj} key={index}/>
                            );
                        }
                    }

                    if(selectedCat === 'set'){
                        if(!dataObj.webCampaign && !dataObj.seted){
                            viewFlag = false;
                            return (
                                <Coupon couponItemDataProp={dataObj} key={index} />
                            );
                        }
                    }else if(selectedCat === 'web'){
                        if(dataObj.webCampaign){
                            viewFlag = false;
                            return (
                                <Coupon couponItemDataProp={dataObj} key={index} />
                            );
                        }
                    }else if(catInfoJson[selectedCat] === 'その他の'){
                        if(dataObj.webCampaign){
                            viewFlag = false;
                            return (
                                <Coupon couponItemDataProp={dataObj} key={index} />
                            );
                        }

                        return dataObj.categories.map((cat:any) => {
                            if(selectedCat === cat.id.toString()){
                                viewFlag = false;
                                return (
                                    <Coupon couponItemDataProp={dataObj} key={index} />
                                );
                            }
                        });
                    }

                    return dataObj.categories.map((cat:any) => {
                        if(selectedCat === cat.id.toString()){
                            viewFlag = false;
                            return (
                                <Coupon couponItemDataProp={dataObj} key={index} />
                            );
                        }
                    });
                }else{
                    viewFlag = false;
                    return (
                        <Coupon couponItemDataProp={dataObj} key={index} />
                    );
                }
            }
        });
    }

    if('status' in couponList){
        if((couponList.status === 'idle' && couponList.data.length === 0) || viewFlag && loadFlag){
            return (
                <div className="coupon-list-area">
                    <div className="none-coupon">
                        <p>この条件のクーポンはありません。</p>
                    </div>
                </div>
            );
        }
    }


    return (
        <div className="coupon-list-area">
            <div className="coupon-list-items">
                {couponItemList}
            </div>
        </div>
    );
}

export default Coupon_area;