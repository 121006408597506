import React, { useState, useEffect } from 'react';

import { reactTagManagerEvViewError } from '../main/functions/functions'

import { store, useSelector } from '../../store/store';

const ErrorNetwork:React.FC = () => {
    const auth:any = useSelector((state) => state.authentication);
    const viewState:Number = useSelector((state) => state.viewData.categoryViewState);

    const [firstMount, setFirstMount] = useState<boolean>(false);
    useEffect(() => {
        setFirstMount(true);
    },[]);

    useEffect(() => {
        if(firstMount){
            if(viewState === 0){
                reactTagManagerEvViewError('screen_offer', auth.data.id);
            }

            if(viewState === 2){
                reactTagManagerEvViewError('screen_offer_detail', auth.data.id);
            }

            if(viewState === 3){
                reactTagManagerEvViewError('screen_menu', auth.data.id);
            }

            if(viewState === 5){
                reactTagManagerEvViewError('screen_basket', auth.data.id);
            }

            if(viewState === 6){
                reactTagManagerEvViewError('screen_usage_history', auth.data.id);
            }
        }
    },[firstMount]);

    return (
        <div className="inner_container error-big-container">
            <div className="error-display">
                <div className="error-image">
                    <img src="images/icon-error.png" alt="" />
                </div>
                <div className="error-message">
                    <p className="error-title">エラー</p>
                    <p className="error-information" style={{
                        padding:"20px"
                    }}>
                        現在、ページをご利用いただけない事象が発生しており、お客様にご迷惑をおかけしておりますことを深くお詫び申し上げます。
                        サービス復旧まで今しばらくお待ちください。
                    </p>
                </div>
            </div>
        </div>
    )
};


export default ErrorNetwork;