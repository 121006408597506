import React, { useEffect, useState  } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '../../store/store';

import { setErrorRed } from '../../slice/setError/setErrorSlice';

import {
    reactTagManagerEvViewErrorDialog,
    reactTagManagerEvViewErrorDialogBtn
} from '../main//functions/functions';
import ErrorNetwork from "./Error_Network";

const Error_dialog:React.FC<{errorStringProp:string}> = ({errorStringProp}) => {
    const dispatch = useDispatch();
    const errorStatus:any = useSelector((state) => state.setError.errorStatus);
    const viewState:Number = useSelector((state) => state.viewData.categoryViewState);
    const offerSetStatus:any = useSelector((state) => state.setOffer.offerStatus);
    const auth:any = useSelector((state) => state.authentication);

    const [errorStatusState, setErrorStatusState] = useState('');

    const errorOk = () => {
        dispatch(setErrorRed({errorStatus:''}));

        if(viewState === 0 && offerSetStatus.indexOf('set') === -1){
            reactTagManagerEvViewErrorDialogBtn('screen_offer', auth.data.id);
        }

        if(viewState === 2){
            reactTagManagerEvViewErrorDialogBtn('screen_offer_detail', auth.data.id);
        }

        if(viewState === 3){
            reactTagManagerEvViewErrorDialogBtn('screen_menu', auth.data.id);
        }

        if(viewState === 5){
            reactTagManagerEvViewErrorDialogBtn('screen_basket', auth.data.id);
        }

        if(viewState === 6){
            reactTagManagerEvViewErrorDialogBtn('screen_usage_history', auth.data.id);
        }
    };

    useEffect(() => {
        setErrorStatusState(errorStringProp);
    });

    useEffect(() => {
        if(viewState === 0 && offerSetStatus.indexOf('set') === -1){
            if(errorStatus){
                const errorStatusType = errorStatus.split('-');
                reactTagManagerEvViewErrorDialog('screen_offer', errorStatusType[1], auth.data.id);
            }
        }

        if(viewState === 2){
            if(errorStatus){
                const errorStatusType = errorStatus.split('-');
                reactTagManagerEvViewErrorDialog('screen_offer_detail', errorStatusType[1], auth.data.id);
            }
        }

        if(viewState === 3){
            if(errorStatus){
                const errorStatusType = errorStatus.split('-');
                reactTagManagerEvViewErrorDialog('screen_menu', errorStatusType[1], auth.data.id);
            }
        }

        if(viewState === 5){
            if(errorStatus){
                const errorStatusType = errorStatus.split('-');
                reactTagManagerEvViewErrorDialog('screen_basket', errorStatusType[1], auth.data.id);
            }
        }

        if(viewState === 6){
            if(errorStatus){
                const errorStatusType = errorStatus.split('-');
                reactTagManagerEvViewErrorDialog('screen_usage_history', errorStatusType[1], auth.data.id);
            }
        }
    },[errorStatusState]);

    let errorTxt = 'クーポンのセットに失敗しました。';
    if(errorStatus.indexOf('errorGet') > -1){
        errorTxt = 'クーポンの取得に失敗しました。';
    }

    if(errorStatus.indexOf('error-basket') > -1){
        errorTxt = 'クーポンの取得に失敗しました。';
    }

    if(errorStatus.indexOf('error-wallet') > -1){
        errorTxt = 'クーポンの取得に失敗しました。';
    }
    if (errorStatus.indexOf('error-network') > -1) {
        return (
            <div className="wrapper error-wrapper">
                <ErrorNetwork />
            </div>
        );
    }
    if(errorStatus.indexOf('error') !== -1){
        return (
            <div className='error-wrapper'>
                <div className="inner_container error-small-container">
                    <div className="error-display">
                        <div className="error-message">
                            <p className="error-title">エラー</p>
                            <p className="error-information">{errorTxt}</p>
                        </div>
                        <div className="error-button">
                            <span onClick={errorOk}>OK</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return (
            <></>
        )
    }
};


export default Error_dialog;